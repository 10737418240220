<template>
    <div class="tabela-supervisor">
        <b-table 
            :items="items" 
            :fields="fields"
            :select-mode="selectMode"
            ref="selectableTable"
            selected-variant=""
            selectable
            responsive
            :tbody-tr-class="rowClass"
            class="tabela-supervisor"
        >

            <template #cell(Status)="row">
                <li > {{ row.value}}</li>
            </template>

            <template #cell(Nome)="row">
                <span>{{row.value}}</span>
            </template>
            
            <template #cell(atendimentos)="row">
                <span>{{row.atendimentos}}</span>
            </template>

            <template #cell(Desconectar)="row">
                <div class="btn" @click="deslogar(row.item)"><i class="fas fa-power-off"></i></div>
            </template>

        </b-table>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import axios from "axios";
export default {
    name:'TabelaSupervisor',

    props: {
        items: {
            type: Array
        },
        perfilUsuario: {
            type: Boolean,
            default: false
        }
    },

    data: function () {
        return {
            fields:[
                { key: 'Status',      label: 'Status',       sortable: true,   sortDirection:'asc' },
                { key: 'Nome',        label: 'Nome',         sortable: true,   sortDirection:'asc' },
                { key: 'TempoMedio',  label: 'Tempo Médio',  sortable: true,   sortDirection:'asc' },
                { key: 'MaiorTempo',  label: 'Maior Tempo',  sortable: true,   sortDirection:'asc' },
                { key: 'Desconectar', label: 'Desconectar',  sortable: true,   sortDirection:'asc', class: 'centraliza-botoes' }
            ],
            selectMode:'single',
        }
    },

    methods: {
        ...mapActions('authentication', ['deslogarUsuario']),
        // muda a cor dos status
        rowClass(item,type) {
            if (!item || type !== "row")
                return
            if (item.Status === "Online")
                return 'status-online'

            return 'status-offline'
        },
        deslogar(data) {
            axios.put("/api/usuario/deslogarUsuario/" + data.Id).then(response => {
                if (response.data.Sucesso) {
                    let indexModificar = this.items.findIndex(f => f.Id == response.data.Id);
                    this.items[indexModificar].Status = response.data.Status;
                }
                this.supervisorNotificacao(response.data.Mensagem, response.data.Sucesso )

                $("#caixa_carregamento").fadeOut();
            });
        },
        supervisorNotificacao(mensagem, sucesso) {
            this.$bvToast.toast(mensagem, {
                title: `Aviso`,
                toaster: 'b-toaster-top-right',
                solid: true,
                variant: sucesso ? 'info' : 'danger',
                appendToast: false,
                autoHideDelay: 3000,
                noCloseButton: true
            });
        },
    }
}
</script>

<style scope>
.status-online li {
    margin-left: 1px;
    font-weight:500;
    color:#28a745;
}

.status-offline li {
    margin-left: 1px;
    font-weight:500;
    color:#dc3545;
}

.b-table-row-selected {
    background-color:rgba(214,230,234,0.87);
}

.tabela-supervisor {
    height: 100%;
    background: #fff;
    margin-bottom: 0 !important;
}
.centraliza-botoes {
    text-align: center;
}
</style>
<template>
    <div class="header-tabela grid-template">   
        <div class="area1">
            <span> {{texto}}</span>
            <slot name="area1"></slot>
        </div>
        <div class="area2">
           <slot name="area2"></slot>
        </div>
        <div class="area3">
            <slot name="area3"></slot>
            <slot name="area4"></slot>
        </div>
   </div>
</template>

<script>
export default {
    name: 'HeaderTabela',

    props: {
        texto:String
    },
}
</script>

<style  scoped>
.header-tabela {
    background-color: var(--cinza-3);
}

.grid-template {
    display: grid;
    width: 100%;
    grid-template-areas: 'area1 area2 area3';
}
.area1 {
    grid-area: area1;
    width: calc(100%/3);
}

.area2 {
    grid-area: area2;
    width: calc(100%/3);
}

.area3 {
    grid-area: area3;
    margin-right: 5px;
    text-align: right
}
</style>
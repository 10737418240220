<template>
    <div class="supervisor-page">
        <Breadcrumb titulo="SUPERVISOR" :items="item" backgroundColor="#808080" />

        <div class="supervisor-page-container-externo-tabela">
            <div class="supervisor-page-container-interno-tabela">
                <header-tabela class="mb-2">
                    <div slot="area3" class="area3">
                        <div class="row py-0">
                            <div class="col-6">
                                <input-pesquisa />
                            </div>
                            <div class="col-6" style="text-align: right;">
                                <botao-padrao texto="Exportar Relatório" />
                            </div>
                        </div>
                    </div>
                </header-tabela>
                <tabela-supervisor :items="listaUsuariosStatus" class="mb-0"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Breadcrumb   from '@/components/breadcrumb/Breadcrumb.vue'
import HeaderTabela from '../components/tabelas/HeaderTabela.vue'
import BotaoPadrao  from '../components/botoes/BotaoPadrao.vue'
import InputPesquisa from '../components/inputs/InputPesquisa.vue'
import TabelaSupervisor from '../components/tabelas/TabelaSupervisor.vue'

export default {
    name: 'SupervisorPage',

    components: {
        Breadcrumb,
        HeaderTabela,
        BotaoPadrao,
        InputPesquisa,
        TabelaSupervisor,
    },

    data: function() {
        return {
            item:[{
                id:'2',
                classe: 'fas fa-user-cog',
                texto:'Supervisor',
                link:'/supervisor'
            }],
        }
    },
    
    computed: {
        listaUsuariosStatus: function () {
            let usuarios_status = this.$store.getters['usuarios/listaUsuariosStatus'];
            if (usuarios_status) {
                return usuarios_status
            }
            return [];
        }
    },

    created() {
        this.buscaUsuariosStatus();
    },

    methods: {
        ...mapActions({
            mostrarSucesso: 'mostradorDeMensagens/mostrarSucesso'
        }),
        buscaUsuariosStatus() {
            this.$store.dispatch('usuarios/buscaUsuariosStatus');
        },
       
    },
}
</script>

<style scoped>
.area3 {
   position: relative;
   float: right;
}

.supervisor-page-container-externo-tabela {
    padding: 12px;
}

.supervisor-page-container-interno-tabela {
    padding: 10px;
    background-color: var(--cinza-3);
}

</style>